import { useEffect, useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Switch,
    useSelect,
} from "@pankod/refine-antd";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import dayjs from "dayjs";
import { InputEditor } from "components/inputs/inputeditor";

interface FunctProps extends IResourceComponentsProps {
    formProps: any;
    form: any;
    queryResult: any;
    formLoading: any;
    onFinish({ }): any;
}

export const CustomerForm: React.FC<FunctProps> = (props) => {
    const [phoneError, setPhoneError] = useState<boolean>(false)
    const [countryCode, setCountryCode] = useState<string>('')
    const [countryInfo, setCountryInfo] = useState<any>(null)

    const t = useTranslate();
    const formProps = props.formProps

    const { selectProps: selectCountryProps } = useSelect<{ id: string, alpha2: string, name_en: string }>({
        resource: "countries",
        optionLabel: "name_en",
        optionValue: "id",
        fetchSize: 250, // 242 countries now
        onSearch: (value) => [
            { field: "search", operator: "eq", value, }
        ],
    });

    const { selectProps: selectLangProps } = useSelect<{ id: string, long_name: string }>({
        resource: "langs",
        optionLabel: "long_name",
        optionValue: "id",
        fetchSize: 200, // 183 langs now
        onSearch: (value) => [
            { field: "search", operator: "eq", value, }
        ],
    });

    const onFinish = (values: any) => {
        return (
            formProps.onFinish &&
            formProps.onFinish({
                ...values,
                birth: Math.trunc(Number(props.form.getFieldValue('birth')) / 1_000),
            })
        );
    };

    useEffect(() => {
        setTimeout(() => {
            const birth = props.form.getFieldValue('birth')
            props.form.setFieldsValue({ birth: birth ? Number(birth) * 1_000 : undefined });
        }, 100)
    }, [props.formLoading, props.queryResult?.dataUpdatedAt])

    useEffect(() => {
        if (countryInfo && selectCountryProps.options) {
            const matchCountry = () => {
                const countryPrefix = countryInfo.name?.toLowerCase().replace(/\s*\([^)]*\)/g, "").replace(/\s+[^\u0000-\u007F].*$/, "").trim();
                if (!countryPrefix) return;

                const matchedCountry = selectCountryProps.options?.find(
                    (country: any) => country.label?.toLowerCase() === countryPrefix
                );
                const matchedLang = selectLangProps.options?.find(
                    (country: any) => country.label?.slice(0, 2).toLowerCase() === countryPrefix.slice(0, 2)
                );

                if (matchedCountry) props.form.setFieldsValue({ country: { id: matchedCountry.value } });
                
                if (matchedLang) props.form.setFieldsValue({ lang: { id: matchedLang.value } });
            };

            matchCountry();
        }
    }, [countryInfo, selectCountryProps.options, props.form]);

    return (
        <Form {...formProps} onFinish={onFinish} layout="vertical">
            <Row >

                <Col xs={24} sm={24} md={24} lg={12} xl={10}
                >
                    <Form.Item
                        label={t("customers.title")}
                        name="title"
                        rules={[{ required: true }]}
                    >
                        <Select
                            options={[
                                {
                                    label: t("customers.mr"),
                                    value: "M",
                                },
                                {
                                    label: t("customers.mme"),
                                    value: "Mme",
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("customers.fname")}
                        name="fname"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("customers.lname")}
                        name="lname"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("pages.booking.email")}
                        name="email"
                        rules={[{ required: true }]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item
                        label={t("customers.mobilephone")}
                        name="phone"
                        rules={[{ required: true }]}
                        initialValue={'+33'}
                    >
                        <IntlTelInput
                            formatOnInit
                            fieldName="phone"
                            inputClassName={`ant-input ${phoneError ? '' : 'ant-input-status-error'}`}
                            placeholder="Enter Your Number"
                            numberType="MOBILE"
                            value={formProps.form?.getFieldValue("phone")}
                            onPhoneNumberChange={(status, phoneNumber, country, fullNumber) => {
                                formProps.form?.setFieldsValue({ "phone": fullNumber.replace(/\s+/g, '') })
                                setPhoneError(status)
                                setCountryCode(country.dialCode || '')
                                setCountryInfo(country)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={t("customers.client_type")} name="raison_sejour" initialValue={"LOISIR"} required>
                        <Select
                            placeholder={t("customers.client_type")}
                        >
                            {/* <Select.Option value={"NULL"} label={t("customers.client_type")} >{t("customers.client_type")}</Select.Option> */}
                            <Select.Option value={"LOISIR"} label={"LOISIR"} >{t('customers.leisure')}</Select.Option>
                            <Select.Option value={"BUSINESS"} label={"BUSINESS"} >{t('customers.business')}</Select.Option>
                            <Select.Option value={"BOTH"} label={"BOTH"} >{`${t('customers.leisure')}/${t('customers.business')}`}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={t("customers.special_price")} name="price_access" initialValue={"DEFAULT"} required>
                        <Select
                            placeholder={t("customers.special_price")}
                        >
                            <Select.Option value={"DEFAULT"} label={"DEFAULT"}>{t('customers.regular_price')}</Select.Option>
                            <Select.Option value={"PRIVILEGE"} label={"PRIVILEGE"} >{t('customers.privileged_rate')}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("customers.auto_billing")}
                        name={"is_mail_auto"}
                        initialValue={false}
                        valuePropName='checked'
                        required
                    >
                        <Switch checked />
                    </Form.Item>
                    <Form.Item
                        label={t("customers.birth_date")}
                        name="birth"
                        rules={[{ required: false }]}
                        getValueProps={(value) => ({
                            value: value ? dayjs(value) : undefined,
                        })}
                    >
                        <DatePicker
                            format={t("short_date")}
                        />
                    </Form.Item>
                </Col>
                <Col xs={1} >
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={10}
                >

                    <Form.Item
                        label={t("pages.booking.room_code")}
                        name="room_code"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t("customers.usual_lang")}
                        name={["lang", "id"]}
                        rules={[{ required: true }]}
                        initialValue={1}
                    >
                        <Select {...selectLangProps} />

                    </Form.Item>
                    <Form.Item
                        label={t("customers.living_country")}
                        name={["country", "id"]}
                        rules={[{ required: true }]}
                    >
                        <Select {...selectCountryProps} />
                    </Form.Item>

                    <Form.Item
                        label={t("address.society_name")}
                        name={['address', 'society_name']}
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("address.address")}
                        name={['address', 'str_address']}
                    // rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("address.city")}
                        name={['address', 'city']}
                    // rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("address.zip")}
                        name={['address', 'zip']}
                    // rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                </Col>

            </Row>
            <Row>
            <Col xs={24}
                >
                <Form.Item
                    label={t("pages.booking.custom_request")}
                    name="comments"
                    rules={[{ required: false }]}
                >
                    <InputEditor />

                </Form.Item >

                </Col>
            </Row>

        </Form>
    );
};
