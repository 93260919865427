import React from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, useForm } from "@pankod/refine-antd";
import { IPartner, IEquipment } from "interfaces";
import { ExtraForm } from "./form";

// export const PartnerEdit: React.FC<IResourceComponentsProps> = () => {
//   const t = useTranslate();
//   const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IPartner>({ 'redirect': 'edit' });
//   const obj_name = queryResult?.data?.data?.default_name

//   return (
//     <Edit saveButtonProps={saveButtonProps} title={`${t('buttons.edit')} ${obj_name}`}>
//       <PartnerForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
//     </Edit>
//   );
// };

export const ExtraEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IEquipment>({ 'redirect': 'edit' });

  return (
    <Edit saveButtonProps={saveButtonProps} title={`${t('buttons.edit')} ${t("menu.extras")}`}>
      <ExtraForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
    </Edit>
  );
};
