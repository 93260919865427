import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Space, Button } from "@pankod/refine-antd";
import { useTranslate, useShow } from "@pankod/refine-core";
import { IBooking } from "interfaces";
import { useNavigate } from "core/customNavigation";
import dayjs from "dayjs";

export const CreateBlockModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [bookingId, setBookingId] = useState<string | undefined>();
  const { queryResult } = useShow<IBooking>();
  const { data } = queryResult;
  const record = data?.data;

  const t = useTranslate();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    show: (id: string) => {
      setBookingId(id);
      setVisible(true);
    },
  }));

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={t("pages.booking.block")}
      visible={visible}
      onCancel={handleClose}
      footer={null}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {record?.bookingrooms.map((item) => (
          <Button 
            key={`block_button_${item.id}`} 
            style={{ width: '100%' }}
            onClick={(e:any) => navigate(`/${t('menu.bookings')}/create?${t('block')}=true&room_id=${item.room_id}&start_date=${item.end}&end_date=${dayjs(item.end).add(1, 'day').set('hour', 12).set('minute', 0).format('YYYY-MM-DDTHH:mm')}`, e)}
            
          >
            {item.room.name}
          </Button>
        ))}
      </Space>
    </Modal>
  );
});