import {IResourceComponentsProps, useShow, useTranslate} from "@pankod/refine-core";

export const TouristTaxCreate: React.FC<IResourceComponentsProps> = () => {

  const t = useTranslate();
  return (
    <div>
  test
    </div>
  );
};