import React, { useEffect } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useNavigation,
} from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
} from "@pankod/refine-antd";
import { ITouristTax } from "interfaces";
import { useParams } from "react-router-dom";

export const TouristTaxEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const { id } = useParams(); // Récupère l'ID de l'URL
  const { push } = useNavigation();

  const [form] = Form.useForm();

  // Utilisez useCustom pour récupérer les données de la taxe de séjour spécifique
  const { data, isLoading } = useCustom<ITouristTax>({
    url: `${apiUrl}/touristtax/${id}`,
    method: "get",
  });

  // Mettre à jour les valeurs du formulaire une fois les données chargées
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data.data);
    }
  }, [data]);

  const onFinish = async (values: ITouristTax) => {
    try {
      // Utilisez fetch pour mettre à jour les données
      await fetch(`${apiUrl}/touristtax/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      push("/touristtax"); // Redirige vers la liste après la mise à jour
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <Edit>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("touristtax.fields.name")}
          name="name"
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("touristtax.fields.max_amount")}
          name={["data", "max"]}
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label={t("touristtax.fields.percent")}
          name={["data", "percent"]}
          rules={[{ required: true, message: t("errors.required") }]}
        >
          <Input type="number" step="0.01" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
