import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useApiUrl,
  useCustom,
} from "@pankod/refine-core";
import { Show, Typography, Tag, Row, Col, Card, Space } from "@pankod/refine-antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { List, Menu, Dropdown } from 'antd';

import { IRoom, IEquipment, IRoomText, IInstruction } from "interfaces";
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';

dayjs.extend(localizedFormat);

const { Title, Text } = Typography;

export const RoomShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const apiUrl = useApiUrl();

  const { queryResult } = useShow<IRoom>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: ypRoomData } = useCustom<any>({
    url: `${apiUrl}/rooms/${record?.id}/get_yeildplanet_free_ids`,
    method: "get",
  });

  const cardStyle = { backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" };

  return (
    <Show isLoading={isLoading} title={`${record?.name}`}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card title={t("rooms.general_info")} style={cardStyle}>
            <List>
              <List.Item>
                <Title level={5}>{t("rooms.name")}:</Title>
                <Text>{record?.name}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.name_short")}:</Title>
                <Text>{record?.name_short}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.start_hour")}:</Title>
                <Text>{record?.start_hour}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.end_hour")}:</Title>
                <Text>{record?.end_hour}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.edit.capacity_max")}:</Title>
                <Text>{record?.capacity_max}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.edit.infant_allowed")}:</Title>
                {record?.infant_allowed ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.edit.area")}:</Title>
                <Text>{record?.area} m<sup>2</sup></Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.is_enable")}:</Title>
                {record?.is_enable ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
              </List.Item>
            </List>
          </Card>
        </Col>
        <Col span={12}>
          <Card title={t("menu.equipments")} style={cardStyle}>
            <Space wrap>
              {record?.equipments?.map((equipment: IEquipment) => (
                <Tag key={equipment.id}>{equipment.label}</Tag>
              ))}
            </Space>
          </Card>
          <Card title={t("rooms.localisation")} style={{...cardStyle, marginTop: '16px'}}>
            <List>
              <List.Item>
                <Title level={5}>{t("rooms.address")}:</Title>
                <Text>{`${record?.address}, ${record?.zip} ${record?.city}, ${record?.country}`}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("position")}:</Title>
                <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${record?.lat}%2C${record?.lng}`}>
                  {`lat ${record?.lat} lng ${record?.lng}`}
                </a>
              </List.Item>
            </List>
          </Card>
          <Card title={t("rooms.tech")} style={{...cardStyle, marginTop: '16px'}}>
            <List>
              <List.Item>
                <Title level={5}>{t("rooms.ip")}:</Title>
                <Text>{record?.ip}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.port")}:</Title>
                <Text>{record?.port}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.door_number")}:</Title>
                <Text>{record?.door_number}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.nac_iden")}:</Title>
                <Text>{record?.nac_iden}</Text>
              </List.Item>
              <List.Item>
                <Title level={5}>{t("rooms.yieldplanet")}:</Title>
                {record?.yp_room_id ? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}
              </List.Item>
            </List>
          </Card>
          {/* <Card title={t("rooms.access_instructions")} style={cardStyle}>
            {record?.intructions?.map((instruction: IInstruction) => (
              <Card key={instruction.id} title={`${t('rooms.texts.app.translate')} ${instruction.lang.short_name}`} style={{ marginBottom: '16px' }}>
                <div dangerouslySetInnerHTML={{ __html: instruction.access_html || '' }} />
              </Card>
            ))}
          </Card> */}
        </Col>
      </Row>
    </Show>
  );
};