import { useModalForm, Modal, Form, Typography, InputNumber } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { IBooking } from "interfaces";
import { forwardRef, useImperativeHandle } from "react";

const { Title } = Typography;

export const ChangeGuestsNoModal = forwardRef((props, ref) => {
  const t = useTranslate();
  const { modalProps, formProps, show, close } = useModalForm<IBooking>({
    action: "edit",
    redirect: false,
  });

  useImperativeHandle(ref, () => ({
    show(id: string) {
      show(id);
    }
  }));

  return (
    <Modal onOk={close} {...modalProps}>
      <Title level={4}>{t("pages.booking.change_number_of_guests")}</Title>
      <Form {...formProps}>
        <Form.Item
          name="context"
          initialValue="update_guests_no"
          hidden
        >
          <input />
        </Form.Item>
        {formProps.form?.getFieldValue("bookingrooms")?.map((bookingroom: any, index: number) => (
          <Form.Item key={bookingroom.id}>
            <Form.Item
              name={["bookingrooms", index, "id"]}
              hidden
              initialValue={bookingroom.id}
            >
              <input />
            </Form.Item>
            <Form.Item
              name={["bookingrooms", index, "nb_guest"]}
              label={`${t("pages.booking.room")} ${bookingroom.room.name}`}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
});