import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";
import { useNavigate, useNavigation } from '../../core/customNavigation';

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  Form,
  Input,
  Icons,
  Button,
} from "@pankod/refine-antd";
import { IRoom } from "interfaces";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const RoomList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show, list, create } = useNavigation()

  const { tableProps, sorter, searchFormProps } = useTable<IRoom, HttpError, IRoomFilterVariables>({
      onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;
      filters.push(
          {
              field: "name",
              operator: "contains",
              value: name,
          },
      );
      return filters;
    },
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });


  return (
    <List
      
      pageHeaderProps={{
        extra: [
          <Button key="page_header_extra_1" onClick={() => list("equipments")}>{`${t('menu.equipments')}`}</Button>,
          <Button key="page_header_extra_2" onClick={() => list("thematics")}>{`${t('menu.thematics')}`}</Button>,
          <Button key="page_header_extra_3" onClick={() => create(t("menu.rooms"))}>+</Button>,
          <Form 
            layout="horizontal"
            onValuesChange={() => searchFormProps.form?.submit()}
            key="page_header_extra_4"
            // size="small"
            {...searchFormProps}
          >
            <Form.Item label="" name="name" style={{ marginBottom: 0 }}>
              <Input
                  placeholder="ID, Title, Content, etc."
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Form>,

        ],
        // subTitle:`${t("yieldanalysis.yieldanalysis")} ${resource?.dateref ? ' - ' + dayjs(resource.dateref).locale(t("short_lang")).format('ddd D/M') : ''} ${resource?.modified ? ` (${t('last_modified')} ` + dayjs(resource.modified).locale(t("short_lang")).format('D/M, HH:mm') + ')' : ''}`
    }}

    >
      <Table
        {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("rooms.room")}
          render={(value, record: IRoom) => <TextField value={value} onClick={(e:any) => {show("rooms", record?.id, e)}}/>}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="capacity_max"
          key="capacity_max"
          title={t("rooms.edit.capacity_max")}
          defaultSortOrder={getDefaultSortOrder("capacity_max", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="yieldplanet"
          key="yieldplanet"
          title={t("YP")}
          render={(_: any, record: IRoom) => <>{record.yp_hotel_id && record.yp_room_id ? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}</>}
        />
        
        <Table.Column<IRoom>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

interface IRoomFilterVariables {
  name: string;
}
