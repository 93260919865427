import {IResourceComponentsProps, useApiUrl, useCustom, useNavigation, useTranslate,} from "@pankod/refine-core";
import {CreateButton, EditButton, List, Space, Table, TextField,} from "@pankod/refine-antd";
import {ITouristTax} from "interfaces";
import React from "react";

export const TouristTaxList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const {show, create} = useNavigation();
    const apiUrl = useApiUrl(); // Récupère l'URL de l'API

    // Utilisation de useCustom pour effectuer une requête GET sur l'endpoint "taxesejour"
    const {data: taxeSejoursData, isLoading} = useCustom<any>({
        url: `${apiUrl}/touristtax`,
        method: "get",
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Si les données existent, récupérez les résultats
    const taxeSejours = taxeSejoursData?.data ?? [];

    return (
        <List
            title={t("touristtax.title")}
            pageHeaderProps={{
                extra: [
                    <CreateButton> {t("touristtax.create")}</CreateButton>,
                ],
            }}
        >
            <Table dataSource={taxeSejours} rowKey="id">
                <Table.Column
                    dataIndex="name"
                    key="name"
                    title={t("touristtax.fields.name")}
                    render={(value, record: ITouristTax) => (
                        <TextField
                            underline
                            style={{cursor: "pointer"}}
                            value={value}
                            onClick={() => show("touristtax", record?.id)}
                        />
                    )}
                />
                <Table.Column
                    dataIndex={["data", "max"]}
                    key="max"
                    title={t("touristtax.fields.max_amount")}
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex={["data", "percent"]}
                    key="percent"
                    title={t("touristtax.fields.percent")}
                    render={(value) => <TextField value={`${value * 100}%`}/>}
                />
                <Table.Column<ITouristTax>
                    title={t("table.actions")}
                    key="actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton hideText size="small" recordItemId={record.id}/>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
