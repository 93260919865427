import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useNavigation,
  useDelete,
  useApiUrl,
  useCustom,
} from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tag,
  Row,
  Col,
  Button,
  ListButton,
  RefreshButton,
  Space,
  Card,
  Modal,
  Form,
  Input,
  useModalForm,
  useForm,
  useSelect,
  Select,
  Popconfirm,
} from "@pankod/refine-antd";
import { useAuthenticatedRequest } from 'core/useAuthenticatedRequest';

import { IBooking, IInfosClean, IBookingRoom, IConsoBookingRoom, IExtraBookingRoom } from "interfaces";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { List, Menu, Dropdown } from 'antd';
import { CheckOutlined, CloseOutlined, CreditCardOutlined, DownOutlined, EditOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";


import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import { ChangeDatesModal } from "./modals/changedatesprices";
import { ChangeHoursModal } from "./modals/changehours";
import { ChangeCleanConsignModal } from "./modals/changecleanconsign";
import { AddExtrasModal } from "./modals/addextras";
import React from "react";
import { AddConsosModal } from "./modals/addconsos";
import { EditConsosModal } from "./modals/editconsos";
import { EditExtrasModal } from "./modals/editextras";
import { useNavigate } from "core/customNavigation";
import { ChangeRoomsModal } from "./modals/changeroom";
import { ChangeGuestsNoModal } from "./modals/changeguestsno";
import { CreateBlockModal } from "./modals/createblock";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;
const { TextArea } = Input;

export const BookingShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IBooking>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  console.log(record?.invoices)

  const rights = {
    admin: true,
    penalty: false,
  }

  if (record?.status.block) {
    return <BlockView record={record} isLoading={isLoading} refetch={refetch} />
  }
  return <BookingView record={record} isLoading={isLoading} refetch={refetch} />
};

const CustomRequestForm = () => {
  const { formProps, form } = useForm<IBooking>({
    action: "edit",
    redirect: false,
  });

  return (
    <Form style={{ width: 'auto' }} layout="horizontal" {...formProps}>
      <Form.Item
        name="context"
        initialValue="update_custom_request"
        hidden
      ><Input /></Form.Item>
      <Form.Item name="custom_request">
        <TextArea rows={4} />
      </Form.Item>
      <Button onClick={() => form.submit()} style={{ backgroundColor: 'green' }} icon={<CheckOutlined style={{ color: "white" }} />} ></Button>
    </Form>
  )
}

interface BookingShowProps extends IResourceComponentsProps {
  record?: IBooking;
  isLoading: boolean;
  refetch: () => any;
}

const BlockView: React.FC<BookingShowProps> = (props) => {
  const { list } = useNavigation();
  const t = useTranslate();

  const { record, isLoading } = props

  const { mutate, isSuccess } = useDelete();

  const deleteItem = () => {
    mutate({
      id: `${record?.id}`,
      resource: 'bookings',
    });
  };

  if (isSuccess) {
    setTimeout(() => {
      list('bookings')
    }, 100)
  }

  const ChangeCleanConsignModalRef = useRef<any>();

  return (
    <>
      <Show
        title={`${t("pages.booking.block")} ${record?.bookingrooms?.[0].room?.name || ''}`}
        isLoading={isLoading}
        pageHeaderProps={{
          extra: [
            <Popconfirm
              key={'extra_block_nav_01'}
              title={t("buttons.confirm")}
              okText={t('buttons.delete')}
              cancelText={t('buttons.cancel')}
              onConfirm={deleteItem}
            >
              <Button danger>{t("buttons.delete")}</Button>
            </Popconfirm>,
          ]
        }}
      >

        <Row>

          <Col xs={24} sm={24} md={24} lg={12} xl={10} offset={7}>

            {record?.bookingrooms.map((item, i) =>
              <Card key={`card_03_rooms${item.id}`} style={{ backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" }}>

                <List>

                  <List.Item>
                    <Title level={5}>{item.room.name}</Title>
                    <Text>{`${dayjs(item.start).locale(t("short_lang")).format('ddd D MMM HH:mm')} - ${dayjs(item.end).locale(t("short_lang")).format('ddd D MMM HH:mm')}`}</Text>
                  </List.Item>

                  <List.Item>
                    <Title level={5}>{t("created")}</Title>
                    <Text>{dayjs(record?.booking_timestamp).locale(t("short_lang")).format('ddd DD/MM/YY hh:mm:ss')}</Text>
                  </List.Item>

                  <CustomRequestForm />

                  {
                    <List.Item>
                      <Title level={5}>{t("pages.booking.infos_clean")}</Title>
                      <Button icon={<EditOutlined />} onClick={() => ChangeCleanConsignModalRef.current.show(record?.id)} size="small"></Button>
                    </List.Item>
                  }

                  <CalcInfosClean infos_clean={item.infos_clean} />

                </List>
              </Card>
            )}
          </Col>
        </Row>

      </Show>
      <ChangeCleanConsignModal ref={ChangeCleanConsignModalRef} />
    </>
  )
  // }

}

const BookingView: React.FC<BookingShowProps> = (props) => {
  const { show } = useNavigation();
  const t = useTranslate();
  const navigate = useNavigate();
  const { sendRequest } = useAuthenticatedRequest();


  const { record, isLoading, refetch } = props


  const menuOption = (
    <Menu>
      <Menu.Item onClick={() => ChangeDatesModalRef.current.show(record?.id)} key={'menu_item_01'}>{t("pages.booking.dates")}</Menu.Item>
      <Menu.Item onClick={() => ChangeHoursModalRef.current.show(record?.id)} key={'menu_item_02'}>{t("pages.booking.hours")}</Menu.Item>
      <Menu.Item onClick={() => ChangeRoomsModalRef.current.show(record?.id)} key={'menu_item_03'}>{t("pages.booking.relocate")}</Menu.Item>
      <Menu.Item onClick={() => ChangeGuestsNoModalRef.current.show(record?.id)} key={'menu_item_04'}>{t("pages.booking.change_guests_no")}</Menu.Item>
      <Menu.Item onClick={() => CreateBlockModalRef.current.show(record?.id)} key={'menu_item_05'}>{t("pages.booking.block")}</Menu.Item>
    </Menu>
  );

  const menuSupplement = (
    <Menu>
      <Menu.Item onClick={() => AddExtrasModalRef.current.show(record?.id)} key={'menu_item_01'}>{`${t("pages.booking.extras")}`}</Menu.Item>
      <Menu.Item onClick={() => AddConsosModalRef.current.show(record?.id)} key={'menu_item_02'}>{t("pages.booking.consos")}</Menu.Item>
      <Menu.Item key={'menu_item_03'}>{t("pages.booking.invoices")}</Menu.Item>
    </Menu>
  );

  const menuPay = (
    <Menu>
      <Menu.Item onClick={async () => await sendRequest({ url: `bookings/${(record?.id)}/send_pay_mail`, method: 'post' })} key={'menu_item_01'}>{t("pages.booking.send_pay_mail")}</Menu.Item>
      <Menu.Item onClick={async () => await proceedPayment()} key={'menu_item_02'}>{t("pages.booking.proceed_payement")}</Menu.Item>
    </Menu>
  );


  const menuMail = (
    <Menu>
      <Menu.Item onClick={async () => await sendRequest({ url: `bookings/${(record?.id)}/send_book_mail`, method: 'post' })} key={'menu_item_01'}>{t("pages.booking.mail_confimation")}</Menu.Item>
      <Menu.Item onClick={async () => await sendRequest({ url: `bookings/${(record?.id)}/send_thk_mail`, method: 'post' })} key={'menu_item_02'}>{t("pages.booking.mail_invoice")}</Menu.Item>
    </Menu>
  );

  const styles = {
    listItem: {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  };

  const landing_pay_url = `${window.location.protocol}//${record?.landing_pay_url}`

  const proceedPayment = async () => {
    await sendRequest({
      url: `bookings/${(record?.id)}/proceed_payment`,
      method: 'post'
    });
    // THEN
    window.open(landing_pay_url, "_blank", 'noopener,noreferrer');
  };

  const ChangeDatesModalRef = useRef<any>();
  const ChangeCleanConsignModalRef = useRef<any>();
  const AddConsosModalRef = useRef<any>();
  const AddExtrasModalRef = useRef<any>();
  const ChangeHoursModalRef = useRef<any>();
  const ChangeRoomsModalRef = useRef<any>();
  const ChangeGuestsNoModalRef = useRef<any>();
  const CreateBlockModalRef = useRef<any>();

  return (
    <>
      <Show
        title={`${t("pages.booking.title")} nº${record?.id || ''}`}
        isLoading={isLoading}
        pageHeaderProps={{
          extra: [
            <Dropdown key={'div_extra_01'} overlay={menuSupplement}>
              <Button
                className="antd-btn-warning">
                <Space>
                  {t("pages.booking.supplement")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <Dropdown key={'div_extra_02'} overlay={menuMail} >
              <Button className="antd-btn-success" hidden={!record?.status['paid']}>
                <Space>
                  {t("pages.booking.email")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <Dropdown key={'div_extra_03'} overlay={menuOption}>
              <Button className="antd-btn-success">
                <Space>
                  {t("pages.booking.options")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <Dropdown key={'div_extra_04'} overlay={menuPay}>
              <Button className="antd-btn-success" hidden={!record?.status?.wait}>
                <Space>
                  {t("pages.booking.payement")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <ListButton key={'div_extra_05'}></ListButton>,
            <RefreshButton key={'div_extra_06'}></RefreshButton>,
          ]
        }}
      >

        <Row>

          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <Card key={"card_01_client"} style={{ backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" }}>
              <List>
                <List.Item style={styles.listItem}>
                  <Title level={5}>{t("pages.booking.client")}</Title>
                  <Text underline style={{ cursor: "grab" }} onClick={(e: any) => {
                    navigate(`/${t('menu.customers')}/show/${record?.client.id}`, e)
                  }
                  }>{record?.client.fancy_name}</Text>
                </List.Item>

                <List.Item style={styles.listItem}>
                  <Title level={5}>{t("pages.booking.email")}</Title>
                  <Text>{record?.client.email}</Text>
                </List.Item>

                <List.Item style={styles.listItem}>
                  <Title level={5}>{t("pages.booking.phone")}</Title>
                  <Text>{record?.client.phone}</Text>
                </List.Item>

                <List.Item style={{ justifyContent: 'center', ...styles.listItem }}>
                  <Title level={5}>{t("pages.booking.custom_request")}</Title>
                </List.Item>

                <CustomRequestForm />

              </List>

            </Card>

            <Card key={"card_02_resa"} style={{ backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" }}>
              <List>

                <List.Item style={styles.listItem}>
                  <Title level={5}>{t("pages.booking.paid")}</Title>
                  <Text>{record?.status.paid ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}</Text>
                </List.Item>

                <List.Item style={styles.listItem}>
                  <Title level={5}>{t("pages.booking.is_insurance")}</Title>
                  <Text>{record?.is_insurance ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}</Text>
                </List.Item>

                <List.Item style={styles.listItem}>
                  <Title level={5}>{t("pages.booking.total_price")}</Title>
                  <Text>{`${record?.total_price ? record?.total_price : '-'} €`}</Text>
                </List.Item>

                {record?.status.refund && <>
                  <List.Item style={styles.listItem}>
                    <Title level={5}>{t("pages.booking.paid_keep")}</Title>
                    <Text>{`${Number(record?.total_price) - Number(record?.total_refund)} €`}</Text>
                  </List.Item>
                  <List.Item style={styles.listItem}>
                    <Title level={5}>{t("pages.booking.paid_cancel_fees")}</Title>
                    <Text>-</Text>
                  </List.Item>
                  <List.Item style={styles.listItem}>
                    <Title level={5}>{t("pages.booking.paid_refund_to_customer")}</Title>
                    <Text>{`${Number(record?.total_refund) - Number('0')/*paid_cancel_fees*/} €`}</Text>
                  </List.Item>

                </>}

                {
                  <List.Item style={styles.listItem}>
                    <Title level={5}>
                      {t("pages.booking.tr_book")} &nbsp;
                      {(record?.payment_landing || record?.status['fullvalid']) && <CreditCardOutlined onClick={() => window.open(landing_pay_url, "_blank", 'noopener,noreferrer')} />}
                    </Title>

                    <Text underline style={{ cursor: "grab" }} onClick={() => {
                      const url = `https://dashboard.stripe.com/${(process.env?.PROD || false as boolean) ? '' : 'test/'}payments/${record?.tr_book}`
                      window.open(url, '_blank', 'noopener,noreferrer')
                    }}>{record?.tr_book}</Text>

                  </List.Item>
                }

                {

                  <List.Item>
                    <Title level={5}>{t("pages.booking.invoice")}</Title>
                    {record?.invoices.map((item, i) =>
                      <React.Fragment key={`pages.booking.invoice.${i}`}>
                        <Text underline style={{ cursor: "grab" }} onClick={() => {
                          const url = `${(process.env?.PROD || false as boolean) ? 'https://admin.mihotel.fr' : 'http://admin.dev.local/erp'}/invoices/view/${item?.id}`
                          window.open(url, '_blank', 'noopener,noreferrer')
                        }}>{item.invoice_computed_no}</Text>
                        <Button icon={<EditOutlined />} onClick={() => null} size="small"></Button>
                      </React.Fragment>
                    )}
                  </List.Item>
                }

                {
                  !['DIRECT_SITE', 'DIRECT_TEL'].includes(record?.camefrom ? record?.camefrom : "") &&
                  <List.Item style={styles.listItem}>
                    <Title level={5}>{t("pages.booking.yieldplanet")}</Title>
                    <Text underline style={{ cursor: "grab" }} onClick={() => {
                      const url = `https://channelmanager.yieldplanet.com/Tools/PartnerOrders?orderId=${record?.yp_order_id}&hotelId=${record?.yp_hotel_id}`
                      window.open(url, '_blank', 'noopener,noreferrer')
                    }}>{record?.yp_order_code}</Text>
                  </List.Item>
                }

                <List.Item style={styles.listItem}>
                  <Title level={5}>{t('pages.booking.channel_manager')}</Title>
                  <Text>{record?.camefrom}</Text>
                </List.Item>

                <List.Item style={styles.listItem}>
                  <Title level={5}>{t("pages.booking.title")}</Title>
                  <Text>{dayjs(record?.booking_timestamp).locale(t("short_lang")).format('ddd DD/MM/YY hh:mm:ss')}</Text>
                </List.Item>

              </List>
            </Card>

          </Col>


          <Col xs={24} sm={24} md={24} lg={12} xl={14}>

            {record?.bookingrooms.map((item, i) =>
              <Card key={`card_03_rooms${item.id}`} style={{ backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" }}>

                <List>

                  <List.Item style={styles.listItem}>
                    <Title level={5}>{item.room.name}</Title>
                    <Text>{`${dayjs(item.start).locale(t("short_lang")).format('ddd D MMM HH:mm')} - ${dayjs(item.end).locale(t("short_lang")).format('ddd D MMM HH:mm')}`}</Text>
                  </List.Item>

                  <List.Item style={styles.listItem}>
                    <RoomCodeForm bookingroom_id={item.id} />
                  </List.Item>

                  <List.Item style={styles.listItem}>
                    <Title level={5}>{t("pages.booking.nb_guests")}</Title>
                    {
                      Number(item.nb_guest) > 2 ?
                        <Tag color={`#149691`}>{`${item.nb_guest}`}</Tag> :
                        <Text>{item.nb_guest}</Text>
                    }
                  </List.Item>

                  {
                    <List.Item style={styles.listItem}>
                      <Title level={5}>{t("pages.booking.infos_clean")}</Title>
                      <Button icon={<EditOutlined />} onClick={() => ChangeCleanConsignModalRef.current.show(record?.id)} size="small"></Button>
                    </List.Item>
                  }

                  <CalcInfosClean infos_clean={item.infos_clean} />

                  <List.Item style={styles.listItem}>
                    <Title level={5}>{t("pages.booking.extras")}</Title>
                    <Button icon={<PlusOutlined />} onClick={() => AddExtrasModalRef.current.show(record?.id)} size="small"></Button>
                  </List.Item>
                  <ShowExtra extrabookings={item?.extrabookingrooms} record={record} refetch={refetch} />

                  <List.Item style={styles.listItem}>
                    <Title level={5}>{t("pages.booking.consos")}</Title>
                    <Button icon={<PlusOutlined />} onClick={() => AddConsosModalRef.current.show(record?.id)} size="small" hidden={!record?.status?.paid}></Button>
                  </List.Item>
                  <ShowConso consobookings={item?.consobookingrooms} refetch={refetch} />


                </List>
              </Card>
            )}

          </Col>

        </Row>
      </Show>
      <ChangeDatesModal ref={ChangeDatesModalRef} />
      <ChangeCleanConsignModal ref={ChangeCleanConsignModalRef} />
      <AddExtrasModal ref={AddExtrasModalRef} />
      <AddConsosModal ref={AddConsosModalRef} />
      <ChangeHoursModal ref={ChangeHoursModalRef} />
      <ChangeRoomsModal ref={ChangeRoomsModalRef} />
      <ChangeGuestsNoModal ref={ChangeGuestsNoModalRef} />
      <CreateBlockModal ref={CreateBlockModalRef} />
    </>
  );
}

interface CalcInfosCleanProps extends IResourceComponentsProps {
  infos_clean: IInfosClean;
}

const CalcInfosClean: React.FC<CalcInfosCleanProps> = ({ infos_clean }) => {
  const t = useTranslate();
  const [hide, setHide] = useState(true)
  const len = Object.keys(infos_clean)?.length ?? 0

  return (<>
    {Object.keys(infos_clean).map((valDate, index) => {
      const infos = infos_clean[valDate]

      return (
        <List.Item hidden={hide && index > 2} key={`list_infosclean_cleantype_${index}`}>
          <Text>{`${dayjs(valDate).format(t('short_date'))}:`}</Text>
          <Text>{t(`pages.booking.infos_cleans.${infos.clean_type}`)}</Text>
          <Text>{infos.comment}</Text>
        </List.Item>
      )
    })}

    {len > 3 && hide && <List.Item><Text></Text><Text><DownOutlined style={{ fontSize: '125%' }} onClick={() => setHide(false)} /></Text><Text></Text></List.Item>}
    {len > 3 && !hide && <List.Item><Text></Text><Text><UpOutlined style={{ fontSize: '125%' }} onClick={() => setHide(true)} /></Text><Text></Text></List.Item>}

  </>)
}

interface ShowExtraProps extends IResourceComponentsProps {
  extrabookings?: [IExtraBookingRoom];
  record?: IBooking;
  refetch: () => void;
}

const ShowExtra: React.FC<ShowExtraProps> = ({ extrabookings, record, refetch }) => {
  const t = useTranslate();
  const [hide, setHide] = useState(true)

  const EditExtrasModalRef = useRef<any>();

  return (<>
    {extrabookings?.map((extrabooking: any, index) => {
      const total_nb = Object.keys(extrabooking?.infos?.date_qtt)?.map((valDate, _) => extrabooking?.infos?.date_qtt[valDate])?.reduce((acc, curr) => acc + curr)
      const unit_price = extrabooking?.custom_unit_price ?? extrabooking?.extra?.price
      const len = extrabookings?.length ?? 0

      return (
        <React.Fragment key={`extra_extrabookings_${extrabooking?.id}_${index}`}>
          <List.Item>
            <Text>
              {extrabooking?.extra.name}
            </Text>
            <Text> <Tag color={extrabooking?.is_paid ? 'green' : 'red'}>{`${unit_price * total_nb}€`}</Tag>
              <Button icon={<EditOutlined onClick={() => EditExtrasModalRef.current.show(extrabooking?.id)} />} onClick={() => (null)} size="small"></Button>&nbsp;
              {(record?.status?.paid && extrabooking?.is_paid) ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}</Text>
          </List.Item>


          {Object.keys(extrabooking?.infos?.date_qtt)?.map((valDate, index) => {
            const nb = extrabooking?.infos?.date_qtt[valDate]
            if (nb > 0) {
              return (<List.Item hidden={hide && len > 3 && index > 2} key={`list_show_extra_${valDate}_${index}`}>
                <Text></Text>
                <Text></Text>
                <Text>{`${dayjs(valDate).format(t('short_date'))}:`}</Text>
                <Text>{nb}</Text>
              </List.Item>)
            }
            return (null)
          })}

          {len > 3 && hide && <List.Item><Text></Text><Text></Text><Text><DownOutlined style={{ fontSize: '125%' }} onClick={() => setHide(false)} /></Text><Text></Text></List.Item>}
          {len > 3 && !hide && <List.Item><Text></Text><Text></Text><Text><UpOutlined style={{ fontSize: '125%' }} onClick={() => setHide(true)} /></Text><Text></Text></List.Item>}
          <EditExtrasModal ref={EditExtrasModalRef} refetch={refetch} />

        </React.Fragment>
      )
    })}
  </>)
}

interface ShowConsoProps extends IResourceComponentsProps {
  consobookings?: [IConsoBookingRoom];
  refetch: () => any;
}

const ShowConso: React.FC<ShowConsoProps> = ({ consobookings, refetch }) => {
  const t = useTranslate();
  const [hide, setHide] = useState(true);
  const len = consobookings?.length ?? 0;

  const EditConsosModalRef = useRef<any>();

  return (
    <>
      {consobookings?.map((consobooking: IConsoBookingRoom, index) => {
        const unit_price = Number(consobooking?.custom_unit_price ?? consobooking?.conso?.price);

        return (
          <React.Fragment key={`conso_consobookings_${consobooking?.id}_${index}`}>
            <List.Item hidden={hide && index > 2}>
              <Text>
                {consobooking?.conso?.name}
              </Text>
              <Text>
                <Tag >x{consobooking?.number}</Tag>
                <Tag color={consobooking?.is_paid ? 'green' : 'red'}>{`${unit_price * consobooking?.number}€`}</Tag>
                <Button icon={<EditOutlined onClick={() => EditConsosModalRef.current.show(consobooking?.id)} />} onClick={() => (null)} size="small" hidden={consobooking?.is_paid}></Button>&nbsp;
                {consobooking?.is_paid ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
              </Text>
            </List.Item>
          </React.Fragment>
        );
      })}
      {len > 3 && hide && <List.Item><Text></Text><Text></Text><Text><DownOutlined style={{ fontSize: '125%' }} onClick={() => setHide(false)} /></Text><Text></Text></List.Item>}
      {len > 3 && !hide && <List.Item><Text></Text><Text></Text><Text><UpOutlined style={{ fontSize: '125%' }} onClick={() => setHide(true)} /></Text><Text></Text></List.Item>}
      <EditConsosModal ref={EditConsosModalRef} refetch={refetch} />
    </>
  );
};

interface RoomCodeFormProps extends IResourceComponentsProps {
  bookingroom_id: string;
}

const RoomCodeForm: React.FC<RoomCodeFormProps> = (props) => {
  const t = useTranslate();
  const { formProps, form } = useForm<IBooking>({
    action: "edit",
    redirect: false,
  });
  const bookingrooms: [IBookingRoom] = formProps.form?.getFieldValue("bookingrooms")


  return (
    <React.Fragment key={`RoomCodeForm_modal_div_${props.bookingroom_id}`}>
      <Title level={5} >{t("pages.booking.room_code")}</Title>
      <Form style={{ width: 'auto', textAlign: 'right', marginTop: '16px' }} layout="horizontal" {...formProps}>
        <Form.Item
          name="context"
          initialValue="update_code"
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="id"
          initialValue={formProps.form?.getFieldValue("booking")?.id}
          hidden
        >
          <Input />
        </Form.Item>
        {bookingrooms?.map((bookingroom: IBookingRoom, id: string | number) => {
          if (bookingroom.id != props.bookingroom_id)
            return (null)
          return (
            <React.Fragment key={`RoomCodeForm__div_${props.bookingroom_id}`}>
              <Form.Item
                name={["bookingrooms"]}
                initialValue={bookingroom.id}
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item name={["bookingrooms", id, "code"]}>
                <Input.Search


                  onPressEnter={() => form.submit()}
                  enterButton={<Button onClick={() => form.submit()} style={{ backgroundColor: 'green' }} icon={<CheckOutlined style={{ color: "white" }} />} ></Button>} />
              </Form.Item>
            </React.Fragment>
          )
        })}
      </Form>
    </React.Fragment>
  )
}
