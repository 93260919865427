import {IResourceComponentsProps, useTranslate} from "@pankod/refine-core";

export const TouristTaxShow: React.FC<IResourceComponentsProps> = () => {

  const t = useTranslate();
  return (
    <div>
        test
    </div>
  );
};