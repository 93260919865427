import {Refine} from "@pankod/refine-core";
import {Layout, notificationProvider,} from "@pankod/refine-antd";
// import { notificationProvider, LoginPage, AntdLayout } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import "styles/antd.less";
// import dataProvider from "@pankod/refine-simple-rest";
import {authProvider} from "./authProvider";
import dataProvider from "./dataProvider";
import {YieldanalysisCreate, YieldanalysisEdit, YieldAnalysisList} from "pages/yieldanalysis";
import {PriceZoneList} from "pages/pricezones";
import {RoomEdit, RoomList, RoomShow, RoomCreate} from "pages/rooms";
import {CustomerCreate, CustomerEdit, CustomerList} from "pages/customers";
import {BookingCreate, BookingEdit, BookingRoomList, BookingShow} from "pages/bookings";
import {Footer, Header, OffLayoutArea, Sider, Title,} from "components/layout";
import {AreaChartOutlined} from "@ant-design/icons"
import {useTranslation} from "react-i18next";

// Fa-Icon
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'

import {LoginPage} from "pages/login/index.d"
import {AgendaCreate, AgendaEdit, AgendaList} from "pages/agendas";
import {EventCreate, EventEdit, EventList} from "pages/events";
import {CustomerShow} from "pages/customers/show";
import {ConsoList, ConsoCreate, ConsoEdit} from "pages/consos";
import {ExtraList, ExtraEdit, ExtraShow, ExtraCreate} from "pages/extras";
import {ExtractList} from "pages/extracts";
import {TemplateEdit, TemplateList} from "pages/templates";
import {
    PartnerCategoryCreate,
    PartnerCategoryEdit,
    PartnerCategoryList,
    PartnerCreate,
    PartnerEdit,
    PartnerList
} from "pages/partners";
import {EquipmentCreate, EquipmentEdit, EquipmentList} from "pages/equipments";
import {ThematicCreate, ThematicEdit, ThematicList} from "pages/thematics";
import {TedeeCreate, TedeeEdit, TedeeList, TedeeShow} from "pages/tedee";
import {TouristTaxCreate, TouristTaxEdit, TouristTaxList, TouristTaxShow} from "./pages/ touristtax";

function App() {
    const {t, i18n} = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    // const testEnv = process.env?.REACT_APP_TEST || 'r'
    // console.log(testEnv)
    // const order = ["posts", ["comments"], "users"]

    return (
        <Refine
            routerProvider={routerProvider}
            notificationProvider={notificationProvider}
            dataProvider={dataProvider(`${process.env?.REACT_APP_API_URL || ''}`)}
            // dataProvider={dataProvider("http://192.168.0.151:8000")}
            // dataProvider={dataProvider("http://192.168.0.151:8000")}
            // dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
            authProvider={authProvider}
            LoginPage={LoginPage}
            // mutationMode="pessimistic"
            resources={[
                // {
                //   name: "posts",
                //   list: PostList,
                //   create: PostCreate,
                //   edit: PostEdit,
                //   show: PostShow,
                // },
                // {
                //   name: "planning",
                //   // icon: <CalendarOutlined />,
                //   options: {
                //     // label: t("menu.planning"),
                //     route: encodeURI(t("menu.planning")),
                //   },
                //   // list: CustomerList,

                // },
                {
                    name: "bookings",
                    // list: BookingRoomTimeline,
                    list: BookingRoomList,
                    show: BookingShow,
                    edit: BookingEdit,
                    create: BookingCreate,
                    options: {
                        // label: t("menu.bookings"),
                        route: encodeURI(t("menu.bookings")),
                    },

                    // icon: <CalendarOutlined />,

                },
                {
                    name: "bookingrooms",
                    // show: BookingRoomShow,
                    // list: BookingRoomList,
                    // options: {
                    //   // label: t("menu.bookings"),
                    //   route: encodeURI(t("menu.bookingsrooms")),
                    // },

                    // icon: <CalendarOutlined />,

                },
                {
                    name: "clients",
                    // icon: <UserOutlined />,
                    list: CustomerList,
                    show: CustomerShow,
                    create: CustomerCreate,
                    edit: CustomerEdit,
                    options: {
                        label: t("menu.customers"),
                        route: encodeURI(t("menu.customers")),
                    },
                },
                {
                    name: "products/extras",
                    list: ExtraList,
                    show: ExtraShow,
                    create: ExtraCreate,
                    edit: ExtraEdit,

                    // icon: <CodeSandboxOutlined />,
                    options: {
                        // route: "products/extras"
                        label: t("menu.extras"),
                        route: encodeURI(t("menu.extras")),
                    },
                },
                {
                    name: "products/consos",
                    list: ConsoList,
                    // show: ConsoShow,
                    edit: ConsoEdit,
                    create: ConsoCreate,

                    // icon: <CodeSandboxOutlined />,
                    options: {
                        label: t("menu.consos"),
                        route: encodeURI(t("menu.consos")),
                    },
                },
                // {
                //   name: "products",
                //   // icon: <CodeSandboxOutlined />,
                //   options: {
                //     // label: t("menu.products"),
                //     route: encodeURI(t("menu.products")),
                //   },
                // },
                {
                    name: "equipments",
                    list: EquipmentList,
                    create: EquipmentCreate,
                    edit: EquipmentEdit,
                },
                {
                    name: "roomtypes",
                    parentName: ""
                },
                {
                    name: "agendas",
                    options: {
                        route: (t("menu.agendas"))
                    },
                    list: AgendaList,
                    create: AgendaCreate,
                    edit: AgendaEdit,
                },
                {
                    name: "partners",
                    options: {
                        route: (t("menu.partners"))
                    },
                    list: PartnerList,
                    create: PartnerCreate,
                    edit: PartnerEdit,
                },
                {
                    name: "partner_categories",
                    options: {
                        route: ('partnercategories')
                    },
                    list: PartnerCategoryList,
                    create: PartnerCategoryCreate,
                    edit: PartnerCategoryEdit,
                },
                {
                    name: "extracts",
                    options: {
                        route: encodeURI(t("menu.extracts"))
                    },
                    list: ExtractList,
                    // create: AgendaCreate,
                    // edit: AgendaEdit,
                },
                {
                    name: "events",
                    options: {
                        route: encodeURI(t("menu.events"))
                    },
                    list: EventList,
                    create: EventCreate,
                    edit: EventEdit,
                },
                {
                    name: "rooms",
                    parentName: "products",
                    options: {
                        // label: t("menu.rooms"),
                        route: encodeURI(t("menu.rooms")),
                    },
                    list: RoomList,
                    show: RoomShow,
                    create: RoomCreate,
                    edit: RoomEdit,
                    // show: PostShow,
                },
                {
                    name: "tedee",
                    options: {
                        // label: t("menu.rooms"),
                        route: encodeURI(t("menu.tedee")),
                    },
                    list: TedeeList,
                    show: TedeeShow,
                    create: TedeeCreate,
                    edit: TedeeEdit,
                },
                                {
                    name: "touriststax",
                    options: {
                        route: encodeURI(t("menu.touristtax")),
                    },
                    list: TouristTaxList,
                    show: TouristTaxShow,
                    create: TouristTaxCreate,
                    edit: TouristTaxEdit,
                },
                {
                    name: t("menu.management"),
                    // icon: <AreaChartOutlined />,
                    options: {
                        // label: t("menu.management"),
                        route: encodeURI(t("menu.management")),
                    },
                    // list: YieldAnalysisList,
                    // create: YieldanalysisCreate,
                    // edit: YieldanalysisEdit,
                    // show: PostShow,
                },
                {
                    name: t("menu.citymanager"),
                    // icon: <AreaChartOutlined />,
                    options: {
                        // label: t("menu.management"),
                        route: encodeURI(t("citymanager")),
                    },
                    // list: YieldAnalysisList,
                    // create: YieldanalysisCreate,
                    // edit: YieldanalysisEdit,
                    // show: PostShow,
                },
                {
                    name: "templates",
                    // icon: <AreaChartOutlined />,
                    options: {
                        // label: t("menu.templates"),
                        route: encodeURI(t("menu.templates")),
                    },
                    list: TemplateList,
                    // create: TemplateEdit,
                    edit: TemplateEdit,
                    // show: PostShow,
                },
                {
                    name: "thematics",
                    options: {
                        route: encodeURI(t("menu.thematics")),
                    },
                    list: ThematicList,
                    create: ThematicCreate,
                    edit: ThematicEdit,
                },
                {
                    name: "pricezones",
                    // parentName: t("menu.management"),
                    icon: <AreaChartOutlined/>,
                    options: {
                        // label: t("menu.pricezones"),
                        route: encodeURI(t("menu.pricezones")),
                    },
                    list: PriceZoneList,
                    // create: YieldanalysisCreate,
                    // edit: YieldanalysisEdit,
                    // show: PostShow,
                },
                {
                    name: "yieldanalysis",
                    // parentName: t("menu.management"),
                    icon: <AreaChartOutlined/>,
                    options: {
                        // label: t("menu.yield"),
                        route: encodeURI(t("menu.yield")),
                    },

                    list: YieldAnalysisList,
                    create: YieldanalysisCreate,
                    edit: YieldanalysisEdit,
                    // show: PostShow,
                },
                {
                    name: t("menu.shops"),
                    // icon: <DesktopOutlined />,
                    options: {
                        // label: t("menu.shops"),
                        route: encodeURI(t("menu.shops")),
                    },

                },
                {
                    name: t("menu.users"),
                    // icon: <TeamOutlined />,
                    options: {
                        // label: t("menu.users"),
                        route: encodeURI(t("menu.users")),
                    },

                },
            ]}
            Title={Title}
            Header={Header}
            Sider={Sider}
            Footer={Footer}
            Layout={Layout}
            OffLayoutArea={OffLayoutArea}
            i18nProvider={i18nProvider}
        />
    );
}

export default App;
library.add(fab, fas, far)
