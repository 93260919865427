import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useCustom,
} from "@pankod/refine-core";
import { Show, Typography, Card, Row, Col, RefreshButton, ListButton, Button, Popconfirm, EditButton, useTable as useTablee } from "@pankod/refine-antd";
import { useNavigate, useNavigation } from '../../core/customNavigation';

import { Avatar, List, Table as ATable, Typography as TAndt } from 'antd';

import { IClient, IBookingRoom, IStripePaymentMethods, IStripeCard, IBookingStatus } from "interfaces";

import 'react-quill/dist/quill.snow.css';
import { AddCardModal } from './modals/addcard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import React, { useRef } from "react";
import { faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ChangeHoursModal } from "./modals/updatecomment";

dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

interface StripeButtonParamsInterface extends IResourceComponentsProps {
  clientId: any,
  paymentMethodId: any
}

const StripeButton: React.FC<StripeButtonParamsInterface> = (param) => {
  const t = useTranslate();
  const {clientId, paymentMethodId} = param
  const { data, isLoading, refetch, isFetched } = useCustom({
      url: `${process.env?.REACT_APP_API_URL || ''}/clients/${clientId}/deletecards`,
      method: "post",
      config: {
        payload: {
          paymentMethodId: paymentMethodId
        }
      },
      queryOptions: {
        enabled: false,
      }
  });

  const handleClick = () => {
      refetch();
  };

  if (isFetched) window.location.reload()

  return (
    <Popconfirm
    key={'extra_block_nav_01'}
    title={t("buttons.confirm")}
    okText={t('buttons.delete')}
    cancelText={t('buttons.cancel')}
    onConfirm={handleClick}
  >
    <Button danger disabled={isLoading}>{isLoading ? <FontAwesomeIcon icon={faSpinner} /> : <FontAwesomeIcon icon={faTrash} />}</Button>
  </Popconfirm>


  );
};

export const CustomerShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show } = useNavigation();
  const navigate = useNavigate();

  const { queryResult } = useShow<IClient>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const {tableQueryResult: bookingListQueryResult, tableProps} = useTablee<IBookingRoom>({
    resource: "bookingrooms",
    metaData: {
      booking__client_id: record?.id
    },
    
  });

  const {data: cardsListQueryResult} = useCustom<IStripePaymentMethods>({
    url: `${process.env?.REACT_APP_API_URL || ''}/clients/${record?.id}/getcards`,
    method: 'get',
    queryOptions: {
      enabled: record?true:false,
    },
  });
  const cards = cardsListQueryResult?.data?.data

  const columnsBookings = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_: any, record: IBookingRoom) => <Text underline style={{cursor:"grab"}} onClick={(e:any) => show("bookings", record?.booking?.id, e)}>{record?.id}</Text>,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      render: (_: any, record: IBookingRoom) => <>{dayjs(record?.start).locale(t("short_lang")).format('DD/MM/YY')}</>,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      render: (_: any, record: IBookingRoom) => <>{dayjs(record?.end).locale(t("short_lang")).format('DD/MM/YY')}</>,
    },
    {
      title: 'Room',
      dataIndex: 'room',
      key: 'room',
      render: (_: any, record: IBookingRoom) => <>{record?.room?.name}</>,
  
    },
    {
      title: 'TotalPrice',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (_: any, record: IBookingRoom) => <>{record?.booking?.total_price}€</>,
  
    },
    {
      title: 'CancelInsurance',
      dataIndex: 'is_insurance',
      key: 'is_insurance',
      render: (_: any, record: IBookingRoom) => <>{record?.booking?.is_insurance? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}</>,
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
      render: (_: any, record: IBookingRoom) => <>{record?.status?.paid? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}</>,
    },
    {
      title: 'Canceled',
      dataIndex: 'canceled',
      key: 'canceled',
      render: (_: any, record: IBookingRoom) => <>{record?.status?.paidcancel? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}</>,
    },
    {
      title: 'Channel',
      dataIndex: ['booking', 'camefrom'],
      key: 'channel',
    },
  ];

  const columnsCards = [
    {
      title: 'Type',
      dataIndex: 'card',
      key: 'type',
      render: (e: IStripeCard) => <>{e.brand}</>,
    },
    {
      title: 'Number',
      dataIndex: 'card',
      key: 'last4',
      render: (e: IStripeCard) => <>**** **** **** {e.last4}</>,
    },
    {
      title: 'Country',
      dataIndex: 'card',
      key: 'country',
      render: (e: IStripeCard) => <>{e.country}</>,
    },
    {
      title: 'Expiracy',
      dataIndex: 'card',
      key: 'exp_month',
      render: (e: IStripeCard) => <>{e.exp_month}/{e.exp_year}</>,
    },
    {
      title: '',
      dataIndex: '',
      key: 'del_card',
      render: (e: { id: any; }) => {
        const cardlistlen = cardsListQueryResult?.data?.data?.length ?? 0
        if (cardlistlen > 1)
          return (<StripeButton clientId={record?.id} paymentMethodId={e?.id}></StripeButton>)
        return <></>
      }
    },
  ]

  const AddCardModalRef = useRef<any>();
  const ChangeHoursModalRef = useRef<any>();
  
  return (
    <>
      <Show
        isLoading={isLoading}
        title={`${t("customers.show_client")}`}
        pageHeaderProps={{
          extra: [
            <Button key={'div_extra_01'} onClick={() => AddCardModalRef.current.show()} className="antd-btn-success">
                + {t("customers.card")}
            </Button>,
            <Button key={'div_extra_02'} onClick={(e:any) => navigate(`/${t('menu.bookings')}/create?${t('menu.customers')}=${record?.id}`, e)} className="antd-btn-success">
                + {t("pages.booking.title")}
            </Button>,
            <ListButton key={'div_extra_03'}></ListButton>,
            <EditButton key={'div_extra_04'}></EditButton>,
            <RefreshButton key={'div_extra_05'}></RefreshButton>,
        ]
        }}
      >

        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>

            <Card key={"card_01_client"} title={record?.fancy_name} style={{ backgroundColor:"#EFEFEF", width: "100%", maxWidth:"500px", padding: '10px', margin: "0px auto 10px auto"}}>

              <List.Item>
                <Title level={5}>{`${t("pages.booking.email")}:`}</Title>
                <Text>{record?.email}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("pages.booking.phone")}:`}</Title>
                <Text>{record?.phone}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("pages.booking.room_code")}:`}</Title>
                <Text>{record?.room_code}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("customers.usual_lang")}:`}</Title>
                <Avatar size={18} src={`/images/lang_img/${record?.lang?.id}_flag.svg`} />
                <Text>{record?.lang?.long_name}  </Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("customers.client_type")}:`}</Title>
                <Text>{record?.raison_sejour == 'NULL'? <CloseOutlined style={{color: "red"}}/> : <>{record?.raison_sejour} <CheckOutlined style={{color: "green"}}/></>}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("customers.special_price")}:`}</Title>
                <Text>{record?.price_access == 'DEFAULT'? <CloseOutlined style={{color: "red"}}/> : <>{record?.price_access} <CheckOutlined style={{color: "green"}}/></>}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("customers.subscribtion")}:`}</Title>
                <Text>{dayjs(record?.created).locale(t("short_lang")).format('ddd DD/MM/YY hh:mm:ss')}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("customers.birth_date")}:`}</Title>
                <Text>{dayjs(((record?.birth||0) as number) * 1000).locale(t("short_lang")).format('ddd DD/MM/YYYY')}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("customers.stripe_id")}:`}</Title>
                <Text underline style={{cursor:"grab"}} onClick={() => {
                  const url = `https://dashboard.stripe.com/${(process.env?.PROD||false as boolean)?'':'test/'}customers/${record?.stripe_id}`
                  window.open(url, '_blank', 'noopener,noreferrer')
                }}>{record?.stripe_id}</Text>
              </List.Item>

              <List.Item>
                <Title level={5}>{`${t("activated")}:`}</Title>
                <Text>{record?.is_deleted? <CloseOutlined style={{color: "red"}}/> : <CheckOutlined style={{color: "green"}}/> }</Text>
              </List.Item>

            </Card>
            
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <Card key={"card_02_address"} title={t("address.address")} style={{ backgroundColor:"#EFEFEF", width: "100%", maxWidth:"500px", padding: '10px', margin: "0px auto 10px auto"}}>
              <List.Item>
                <Text>{record?.address?.full_desc}</Text>
              </List.Item>
            </Card>
            <Card key={"card_03_cards_stripe"} title={t("customers.cards")} style={{ backgroundColor:"#EFEFEF", width: "100%", maxWidth:"500px", padding: '10px', margin: "0px auto 10px auto"}}>
              <ATable 
                  columns={columnsCards}
                  dataSource={cards as any}
                  rowKey="id"
                  pagination={false}
              />
            </Card>
          </Col>

        </Row>

        <Card key={"card_02_custom_request"} title={t("pages.booking.custom_request")}  extra={<Button icon={<EditOutlined />} onClick={() => ChangeHoursModalRef.current.show(record?.id)}></Button>} style={{ backgroundColor:"#EFEFEF", width: "100%", padding: '10px', margin: "0px auto 10px auto"}}>
        {record?.comments && <div dangerouslySetInnerHTML={{__html : `${record?.comments}`}}></div>}
        </Card>
        <Card key={"card_03_bookings"} title={t("menu.bookings")} style={{ backgroundColor:"#EFEFEF", width: "100%", padding: '10px', margin: "0px auto 10px auto"}}>
          <ATable 
            {...tableProps}
            columns={columnsBookings}
            rowKey="id"
            onRow={(record) => ({
              style: {
                backgroundColor: record?.status?.paidcancel == true ? 'rgba(255, 0, 0, 0.1)' : 'inherit',
              },
            })}
          />
        </Card>
      </Show>
      <AddCardModal ref={AddCardModalRef} sCustomerId={record?.stripe_id} record={record} />
      <ChangeHoursModal ref={ChangeHoursModalRef}/>
    </>
  );
};
