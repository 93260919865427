import React, { useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import { Col, Form, Input, Select, Switch, Typography, useSelect, FormInstance, FormProps } from "@pankod/refine-antd";
import { IEquipmenttext, IConso, IRoom } from "interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextArea } from "react-mde";

const { Title, Text } = Typography;

interface FunctProps extends IResourceComponentsProps {
    formProps: FormProps<{}>;
    form: FormInstance<{}>;
    queryResult: any;
    formLoading: boolean;
}

export const ConsoForm: React.FC<FunctProps> = ({ form, formLoading, formProps, queryResult }) => {
    const t = useTranslate();
    const col_center_half_xs = { span: 24, offset: 0 }
    const col_center_full_lg = { span: 12, offset: 6 }

    return (
        <Col xs={col_center_half_xs} lg={col_center_full_lg}>
            {/* {!formLoading && <ConsoLangTextForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />} */}
            {!formLoading && <ConsoInfoForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
        </Col>
    )
}

const ConsoInfoForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, queryResult, form } = props

    const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IRoom>({
        resource: 'rooms',
        optionValue: "id",
        optionLabel: "name",
        debounce: 100,
        fetchSize: 100,
    });

    if (roomSelectProps?.loading == true || roomQueryResult?.data?.data === undefined) {
        return (<></>)
    }

    const iiselected = queryResult?.data?.data?.rooms?.map((room: IRoom) => ({ value: room.id, label: undefined })) as unknown as { value: string; label: string; }

    return (
        <Form {...props.formProps} layout="vertical">
            <Form.Item
                label={t("name")}
                name="name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("price")}
                name="price"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("tva")}
                name="tva"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("status")}
                name="status"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={t("status_internal")}
                name="status_internal"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            <Form.Item
                label={t("full_desc")}
                name="full_desc"
                rules={[{ required: true }]}
            >
                <TextArea value={""} onChange={function (value: string): void {
                    throw new Error("Function not implemented.");
                } } onPaste={function (event: React.ClipboardEvent<Element>): void {
                    throw new Error("Function not implemented.");
                } } onDrop={function (event: React.DragEvent<Element>): void {
                    throw new Error("Function not implemented.");
                } } ></TextArea>
            </Form.Item>

        </Form>
    );
};

const ConsoLangTextForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading } = props
    const texts: IEquipmenttext[] = queryResult?.data?.data?.texts;
    const defaultTexts: IEquipmenttext[] = [
        { "name": "", "lang": { "id": '1', 'short_name': 'FR' } },
        { "name": "", "lang": { "id": '2', 'short_name': 'EN' } }
    ];

    const equipmenttexts = (texts) ? texts : defaultTexts;
    console.log(equipmenttexts)

    return (
        <Form {...formProps} layout="vertical">
            {equipmenttexts?.map((equipmenttext, id) => <ConsoTextForm id={id} equipmenttext={equipmenttext} key={`TextPartnerForm_${id}`} />)}
        </Form>
    )
}

interface ConsoTextFormProps extends IResourceComponentsProps {
    equipmenttext: IEquipmenttext;
    id: any;
}


const ConsoTextForm: React.FC<ConsoTextFormProps> = (props) => {
    const t = useTranslate();
    const { equipmenttext, id } = props

    return (
        <React.Fragment>

            <Form.Item name={['texts', id, 'lang', 'id']} initialValue={equipmenttext.lang.id} hidden>
                <Input />
            </Form.Item>

            <Form.Item label={`${t('rooms.texts.app.translate')} ${equipmenttext?.lang?.short_name}`} name={['texts', id, 'name']} rules={[{ max: 45 }]} required>
                <Input />
            </Form.Item>

            <Form.Item hidden name={['texts', id, 'id']} />
        </React.Fragment>
    )
}
