import React from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {  Create, useForm } from "@pankod/refine-antd";
import { IConso, IPartner, IPartnerCategory } from "interfaces";
import { ConsoForm } from "./form";

export const ConsoCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IConso>({ 'redirect': 'edit' });

  return (
    <Create saveButtonProps={saveButtonProps} title={`${t("buttons.create")}s ${t("menu.consos")}`}>
      <ConsoForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
    </Create>
  );
};
