import React from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {  Create, useForm } from "@pankod/refine-antd";
import { IEquipment, IPartner, IPartnerCategory } from "interfaces";
import { ExtraForm } from "./form";

export const ExtraCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IEquipment>({ 'redirect': 'edit' });

  return (
    <Create saveButtonProps={saveButtonProps} title={`${t("buttons.create")}s ${t("menu.extras")}`}>
      <ExtraForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
    </Create>
  );
};
