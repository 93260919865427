import React, { useState } from "react";

import {
  useTranslate,
  useTitle,
  CanAccess,
  ITreeMenu,
  useRefineContext,
  useRouterContext,
} from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons, useMenu, Modal, useModal } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { useTranslation } from "react-i18next";
import { UnorderedListOutlined, UserSwitchOutlined, AreaChartOutlined, CalendarOutlined, CodeSandboxOutlined, DesktopOutlined, LogoutOutlined, TeamOutlined, UserOutlined, CoffeeOutlined, CrownOutlined, BankOutlined, BlockOutlined, ExclamationCircleOutlined, SolutionOutlined, HddOutlined, ExperimentOutlined, SettingOutlined } from "@ant-design/icons"
import { authProvider } from "authProvider";

// const { UnorderedListOutlined } = Icons;

// // function createListMenuItem() {
// //   // const listMenuItem: [ITreeMenu] = []
// //   // const menuItem: ITreeMenu = [
// //   const menuItem: ITreeMenu = [
// //     {
// //       canCreate: false,
// //       canDelete: undefined,
// //       canEdit: false,
// //       canShow: false,
// //       children: [],
// //       create: undefined,
// //       edit: undefined,
// //       icon: <></>,
// //       key: "/Planning",
// //       label: "Planning,",
// //       list: undefined,
// //       name: "planning,",
// //       options: {label: 'Planning', route: 'Planning'},
// //       parentName: undefined,
// //       route: "/Planning",
// //       show: undefined,
// //       }
// //   ]

  
// }

export const Sider: React.FC = () => {
  // const { t, i18n } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();
  const Title = useTitle();
  const { SubMenu } = Menu;

  const t = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();

  const { modalProps, show } = useModal();

  const listMenuItem: any[] = [
    {
      // key: "/Planning",
      label: t("menu.bookings"),
      name: t("menu.bookings"),
      route: encodeURI(t("menu.bookings")),
      icon: <CalendarOutlined />,

      // list: undefined,
      // create: undefined,
      // edit: undefined,
      // show: undefined,

      // canCreate: undefined,
      // canDelete: undefined,
      // canEdit: undefined,
      // canShow: undefined,

      children: [],
      parentName: undefined,
      customsroutes : [],
    },
    {
      label: t("menu.customers"),
      name: t("menu.customers"),
      route: encodeURI(t("menu.customers")),
      icon: <UserOutlined />,

      // list: undefined,
      // create: undefined,
      // edit: undefined,
      // show: undefined,

      // canCreate: undefined,
      // canDelete: undefined,
      // canEdit: undefined,
      // canShow: undefined,

      children: [],
      parentName: undefined,
      customsroutes : [],
    },
    {
      label: t("menu.products"),
      name: t("menu.products"),
      route: encodeURI(t("menu.products")),
      icon: <CodeSandboxOutlined />,

      // list: undefined,
      // create: undefined,
      // edit: undefined,
      // show: undefined,

      // canCreate: undefined,
      // canDelete: undefined,
      // canEdit: undefined,
      // canShow: undefined,

      children: [
        {
          label: t("menu.rooms"),
          name: t("menu.rooms"),
          route: undefined,
          icon: <BankOutlined />,
    
          // list: undefined,
          // create: undefined,
          // edit: undefined,
          // show: undefined,
    
          // canCreate: undefined,
          // canDelete: undefined,
          // canEdit: undefined,
          // canShow: undefined,
    
          children: [
			{
				label: "Général",
				name: t("menu.rooms"),
				route: encodeURI(t("menu.rooms")),
				icon: null, 
				children: [],
				parentName: undefined,
				customsroutes : [],
			  },
			  {
				label: "Gourguillon",
				name: t("menu.tedee"),
				route: encodeURI(t("menu.tedee")),
				icon: undefined,
				children: [],
				parentName: undefined,
				customsroutes : [],
			  },
		  ],
          parentName: undefined,
          customsroutes : [],
        },
        {
          label: t("menu.extras"),
          name: t("menu.extras"),
          route: encodeURI(t("menu.extras")),
          icon: <CrownOutlined />,
    
          // list: undefined,
          // create: undefined,
          // edit: undefined,
          // show: undefined,
    
          // canCreate: undefined,
          // canDelete: undefined,
          // canEdit: undefined,
          // canShow: undefined,
    
          children: [],
          parentName: undefined,
          customsroutes : [],
        },
        {
          label: t("menu.consos"),
          name: t("menu.consos"),
          route: encodeURI(t("menu.consos")),
          icon: <CoffeeOutlined />,
    
          // list: undefined,
          // create: undefined,
          // edit: undefined,
          // show: undefined,
    
          // canCreate: undefined,
          // canDelete: undefined,
          // canEdit: undefined,
          // canShow: undefined,
    
          children: [],
          parentName: undefined,
          customsroutes : [],
        },
        {
          label: t("menu.partners"),
          name: t("menu.partners"),
          route: encodeURI(t("menu.partners")),
          icon: <UserSwitchOutlined />,
    
          children: [],
          parentName: undefined,
          customsroutes : [],
        }
      ],
      parentName: undefined,
      customsroutes : [],
    },
    {
      label: t("menu.management"),
      name: t("menu.management"),
      route: "menu.management",
      icon: <AreaChartOutlined />,
      parentName: undefined,
      customsroutes : [],

      children: [
        {
          label: t("menu.pricezones"),
          name: t("menu.pricezones"),
          route: encodeURI(t("menu.pricezones")),
          icon: <BlockOutlined />,    
          parentName: undefined,
          customsroutes : [t("menu.yield")],
          children: [],
        },
        {
          label: t("menu.agendas"),
          name: t("menu.agendas"),
          route: "menu.agendas",
          icon: <CalendarOutlined />,
          parentName: undefined,
          customsroutes : [],
          children: [
            {
              label: t("menu.agendas"),
              name: t("menu.agendas"),
              route: encodeURI(t("menu.agendas")),
              icon: undefined,
                
              children: [],
              parentName: undefined,
              customsroutes : [],
            },
            {
              label: t("menu.events"),
              name: t("menu.events"),
              route: encodeURI(t("menu.events")),
              icon: undefined,
                
              children: [],
              parentName: undefined,
              customsroutes : [],
            }
          ],
        },
        {
          label: t("menu.extracts"),
          name: t("menu.extracts"),
          route: encodeURI(t("menu.extracts")),
          icon: <AreaChartOutlined />,

          children: [],
          parentName: undefined,
          customsroutes : [],
        },
        {
          label: t("menu.templates"),
          name: t("menu.templates"),
          route: encodeURI(t("menu.templates")),
          icon: <AreaChartOutlined />,

          children: [],
          parentName: undefined,
          customsroutes : [],
        },
        {
          label: t("menu.touristtax"),
          name: t("menu.touristtax"),
          route: encodeURI(t("menu.touristtax")),
          icon: <AreaChartOutlined />,

          children: [],
          parentName: undefined,
          customsroutes : [],
        }
      ],
    },
    {
      label: t("menu.shops"),
      name: t("menu.shops"),
      route: encodeURI(t("menu.shops")),
      icon: <DesktopOutlined />,

      // list: undefined,
      // create: undefined,
      // edit: undefined,
      // show: undefined,

      // canCreate: undefined,
      // canDelete: undefined,
      // canEdit: undefined,
      // canShow: undefined,

      children: [],
      parentName: undefined,
      customsroutes : [],
    },
    {
      label: t("menu.users"),
      name: t("menu.users"),
      route: encodeURI(t("menu.users")),
      icon: <TeamOutlined />,

      // list: undefined,
      // create: undefined,
      // edit: undefined,
      // show: undefined,

      // canCreate: undefined,
      // canDelete: undefined,
      // canEdit: undefined,
      // canShow: undefined,

      children: [],
      parentName: undefined,
      customsroutes : [],
    },
    // {
    //   label: t("menu.bookings"),
    //   name: t("menu.bookings"),
    //   route: encodeURI(t("menu.bookings")),t("menu.bookings").replace(/\s/g, '-'),
    //   icon: <TeamOutlined />,

    //   list: undefined,
    //   create: undefined,
    //   edit: undefined,
    //   show: undefined,

    //   canCreate: undefined,
    //   canDelete: undefined,
    //   canEdit: undefined,
    //   canShow: undefined,

    //   children: [],
    //   parentName: undefined,
    //   customsroutes : [],
    // }
  ]
  

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: any) => {
      const { icon, label, route, name, children, parentName, customsroutes } = item;

      if (children.length > 0) {
        return (
          <SubMenu
            key={route}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = `/${route}` === selectedKey || customsroutes.some((e: string) => `/${e}` === selectedKey);
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        {hasDashboard && (
          <Menu.Item
            key="dashboard"
            style={{
              fontWeight: selectedKey === "/" ? "bold" : "normal",
            }}
            icon={<Icons.DashboardOutlined />}
          >
            <Link to="/">{t("dashboard.title", "Dashboard")}</Link>
            {!collapsed && selectedKey === "/" && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        )}

        {renderTreeView(listMenuItem, selectedKey)}
        <Menu.Item key="logout" onClick={show} icon={<LogoutOutlined />}>
          {t("buttons.logout", "Logout")}
        </Menu.Item>
      </Menu>
      <Modal onOk={() => authProvider.logout({})} cancelText={t("buttons.cancel")} okText={t("buttons.confirm")} {...modalProps}>
        <ExclamationCircleOutlined  style={{"color": "red"}} /><br/>
        {t("buttons.logout", "Logout")}
      </Modal>
    </AntdLayout.Sider>
  );
};
